form > div {
    margin-top: 24px;
}
form > div.p-checkbox {
    margin-top: 8px;
}
form input {
    min-width: 12rem;
    width: 100%;
}
form .p-dropdown,.p-calendar {
    min-width: 12rem;
    width: 100%;
}