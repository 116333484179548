@import 'prismjs/themes/prism-coy.css';

.p-sortable-column-icon {
    padding: 2px;
    background-color: #ADD8E6;
    border-radius: 2px;
}

.p-tabview {
    width: 100%;
}

.p-float-label-input-text {
    width: 100%;
}

.error-border {
    border: 1px solid red !important;

}

.p-field {
    font-weight: bolder;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.p-float-label label {
    color: black;
    font-size: 15px !important;
    margin-bottom: 1rem !important;
}

.tenant-header {
    display: inline-block;
    width: 90%;

    h5 {
        font-size: 24px;
        font-Weight: bold;
    }

    p {
        font-size: 20px;
        font-Weight: bold;
    }
}

.user-invite {
    .p-dialog-content>div {
        margin-bottom: 8px;
    }
}

.tooldrop-header {
    display: flex;
    justify-content: flex-end;

    button {
        min-width: 72px;
        margin-right: 8px;
    }

    input {
        max-width: 100%;
    }
}

#Config {
    .p-dropdown {
        width: 100%;
    }
}

#ConcessionsData {
    .p-column-filter-menu-button, .p-column-filter-clear-button{
        display: none !important;
    }
    .p-datatable .p-datatable-thead>tr>th{
        align-items: center;
        text-align: center;
        justify-content: center;
        padding:0.6rem;
        text-align: center !important;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        text-align: center !important;
        justify-content: center;
        padding: 0px;
        height: 30px !important;

    }
}

#ConcessionsGateNow {

    .gate-now-datatable {
        overflow: auto;
    }

    .p-datatable.p-datatable-gridlines .p-datatable-thead {
        overflow: auto !important;
        /* Enable scrolling for the header */
        z-index: 1;
        /* Ensure the header stays above the body */
        position: sticky;
        top: 0;
        /* Stick it to the top of the container */
        background-color: white;
        /* Optional: Ensure the background color is consistent */
    }

    .p-datatable .p-column-header-content {
        display: block;
    }

    .p-fluid .p-dropdown {
        display: inline-flex
    }

    .row-red {
        background-color: #ffcccc;
        /* Light red background */
    }

    .p-dropdown {
        max-width: 245px;
    }

    .p-datatable .p-datatable-thead>tr>th {
        padding: 1rem 0.3rem;
        text-align: center;
    }

    .fa-badge-check:before {
        color: green;
    }

    .fa-circle-xmark:before,
    .fa-times-circle:before,
    .fa-xmark-circle:before {
        color: red;
    }

    .fa-solid,
    .fass {
        font-size: x-large;
    }

    .p-column-filter-menu-button,
    .p-column-filter-clear-button {
        visibility: hidden;
    }

    .p-dropdown-label {
        overflow: visible;
    }

}

.tenant-table {
    .p-datatable-header button {
        margin-right: 8px;
    }

    .day-icon,
    .active-check {
        display: table;
        margin: 0 auto;

        i {

            &.pi-check {
                color: green;
            }

            &.pi-times {
                color: red;
            }

            &.pi-question {
                color: blue;
            }
        }
    }

    .day-col {
        min-width: 120px;
        max-width: 120px;

        &.week {
            border-left: 4px solid #777 !important;
        }
    }

    .p-datatable-table {
        .name {
            min-width: 400px;
        }

        .is-active {
            min-width: 100px;
            max-width: 100px;
            border-right: 4px solid #777 !important;
        }

        .summations {
            min-width: 240px;
            border-left: 4px solid #777 !important;

            p {
                font-size: 20px;
            }
        }
    }
}

#Home{
    
    .p-datatable .p-datatable-thead>tr>th{
        padding:  8px;
    }
    .p-datatable .p-datatable-header {
        padding: 8px;
    }
    .p-datatable .p-datatable-tbody>tr>td{
        padding: 6.5px;
        font-size: small;
    }
}
#TenantDashboard{
    .fa-badge-check:before {
        color: green;
        font-size: x-large;
    }
    .fa-circle-check:before {
        color: green;
        font-size: x-large;
    }
    
    .fa-circle-xmark:before {
        color: red;
        font-size: x-large;
    }    
    .p-column-filter-menu-button, .p-column-filter-clear-button{
        display: none !important;
    }
    .p-datatable .p-datatable-thead>tr>th{
        align-items: center;
        text-align: center;
        justify-content: center;
        padding:0.6rem;
        text-align: center !important;
    }
    .p-datatable{
        border: 1px solid lightgray;
    }
    .p-datatable-header{
        background-color: white;
        border: 1px solid rgb(238, 236, 236);
    }
    .p-datatable .p-datatable-tbody>tr>td {
        text-align: center !important;
        justify-content: center;
        padding: 0px;
        height: 60px;
        width:50px;
    }
}
#Hours {
    .p-column-filter-menu-button, .p-column-filter-clear-button{
        display: none !important;
    }
    .p-datatable .p-datatable-thead>tr>th{
        align-items: center;
        text-align: center;
        justify-content: center;
        padding:1rem;
        text-align: center !important;
    }
    .p-datatable .p-datatable-tbody>tr>td {
        text-align: center !important;
        justify-content: center;
        padding: 10px;
        height: 5px;
        width:5px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th{
        padding:1rem;
    }
    .p-datatable .p-datatable-tbody>tr.changed-row {
        background-color: #d5e8d4;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        max-width: 80px;
        min-width: 80px;
        max-height: 18px !important;
        min-height: 50px !important;
    }

    .p-inputtext {
        margin: 0;
        max-height: inherit;
        height: calc(100%);
    }

    .grid {
        margin-top: -20px;
        margin-bottom: -20px;
    }

    .col-3 {
        padding-left: 0px;
    }
}

#OperationHours {
    .p-datatable .p-datatable-tbody>tr.changed-row {
        background-color: #d2f6d0;
    }

    .p-datatable .p-datatable-tbody>tr>td {
        min-width: 80px;
        min-height: 67px;

    }

    .grid {
        margin-top: -20px;
        margin-bottom: -20px;
    }

    .col-6 {
        padding: 0rem;
        width: 50%;
        height: 40px;
    }

    .p-inputtext {
        margin: 0;
        width: calc(100%);
        height: calc(100%);
        max-width: 100%;
    }

    .p-button {
        margin-left: 1rem;
        width: 40%;
    }

    .p-datatable .p-column-header-content {
        display: block;
        text-align: center;
    }

    .p-datatable .p-datatable-tbody>tr.highlighted-row {
        background-color: #ffcccc;
        /* Light red */
    }
}

#Discrepancy {
    .p-datatable .p-datatable-tbody>tr>td {
        width: 10%;
    }

}

#Units {
    .p-inputtext:enabled:hover {
        border-color: red;
    }
}

#GateNowMap {
    min-width: 697px;

    .legend {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: Arial, sans-serif;
        font-size: 16px;
        z-index: 1000;
        max-width: 520px;
    }

    .legend-item {
        // width: 20px;
        // height: 20px;
        // display: inline-block;
        // border-radius: 5px;
        // vertical-align: middle;
        // margin-right: 10px;
        // border: 1px solid #000; /* Optional: adds a border to the color box */
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
        color: white;
        font-size: 1.2rem;
        padding: 2px 5px;
        min-height: 20px;
        opacity: 0.8;
        border: 2px solid #000;

        &.out-of-range {
            border: 3px dashed #000;
        }
    }

    .legend-text {
        display: inline;
        margin-right: 15px;
        vertical-align: middle;
    }

    .switch-text {
        display: inline-block;
        margin: 2px 4px 0 0;
        vertical-align: top;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .p-inputswitch {
        vertical-align: top;
        margin: 2px 0;
    }

    .mapboxgl-ctrl-bottom-right {
        display: none;
    }

    .mapboxgl-ctrl-bottom-left {
        display: none;
    }

    .mapboxgl-popup-content {

        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        text-align: center;
        font-size: small;
    }

    .acaa-marker {
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
        color: white;
        font-size: 1.2rem;
        padding: 2px 5px;
        min-height: 20px;
        opacity: 0.8;
        border: 2px solid black;

        &.out-of-range {
            border: 3px dashed #000;
        }
    }

    .acaa-marker-dashed {
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        vertical-align: middle;
        color: white;
        font-size: 1.2rem;
        padding: 2px 5px;
        min-height: 20px;
        opacity: 0.8;
        border: 3px dashed #000;
    }

    .triangle-down {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #4f4f4f;
        margin: auto;
        margin-top: 2px;
        position: absolute;
        bottom: -14px;
        right: 0px;
    }

    .triangle-down-white {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        //border-top: 10px solid #ecdfdf;
        border-top: 10px solid #4f4f4f;
        margin: auto;
        margin-top: 2px;
        position: absolute;
        bottom: -14px;
        right: 0px;
    }
}

.p-tabmenu .p-tabmenu-nav {
    border: 0px;
}

@media only screen and (max-width: 600px) {
    .tooldrop-header {
        display: inline-block;

        >* {
            margin-right: 0px;
            margin-bottom: 8px;
            max-width: 100%;
        }
    }
}